.photoviewer-modal {
  position: absolute;
  z-index: 1090;
  width: 320px;
  height: 320px;
  overflow: visible !important;
  color: #333;
  cursor: default;
  background-color: rgba(255, 255, 255, 0.92);
  border: 1px solid rgba(0, 0, 0, 0.64);
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.32);
}

.photoviewer-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.photoviewer-maximized {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}

.photoviewer-maximized.photoviewer-modal {
  border-width: 0;
  border-radius: 0;
}

.photoviewer-maximized .photoviewer-header {
  border-radius: 0;
}

.photoviewer-maximized .photoviewer-resizable-handle {
  display: none;
}

.photoviewer-toolbar {
  font-size: 0;
}

.photoviewer-button {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin: 0;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background: none;
  border-width: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.photoviewer-button:hover {
  color: #111;
}

.photoviewer-button:focus {
  background-color: rgba(0, 0, 0, 0.08);
}

.photoviewer-button svg {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.photoviewer-header {
  position: relative;
  z-index: 2;
  height: 30px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.photoviewer-header .photoviewer-toolbar {
  float: right;
}

[dir=rtl] .photoviewer-header .photoviewer-toolbar {
  float: left;
}

.photoviewer-header .photoviewer-button {
  height: 30px;
}

.photoviewer-header .photoviewer-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.photoviewer-header .photoviewer-button-close:hover {
  color: #fff;
  background-color: #ff4545;
}

.photoviewer-title {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}

.photoviewer-stage {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 40px;
  left: 0;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.64);
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  background-color: #fff;
  overflow: hidden;
}

.photoviewer-stage.stage-ready {
  text-align: center;
  font-size: 0;
}

.photoviewer-stage.stage-ready::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.photoviewer-image {
  position: absolute;
  display: inline-block;
  min-width: auto;
  max-width: none;
}

.photoviewer-image.image-ready {
  position: static;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.photoviewer-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
}

.photoviewer-footer .photoviewer-toolbar {
  display: inline-block;
}

[dir=rtl] .photoviewer-button-prev,
[dir=rtl] .photoviewer-button-next {
  transform: rotate(180deg);
}

.photoviewer-resizable-handle {
  position: absolute;
  z-index: 10;
}

.photoviewer-resizable-handle-e {
  top: 0;
  right: -5px;
  bottom: 0;
  left: auto;
  width: 10px;
  cursor: e-resize;
}

.photoviewer-resizable-handle-s {
  top: auto;
  right: 0;
  bottom: -5px;
  left: 0;
  height: 10px;
  cursor: s-resize;
}

.photoviewer-resizable-handle-w {
  top: 0;
  right: auto;
  bottom: 0;
  left: -5px;
  width: 10px;
  cursor: w-resize;
}

.photoviewer-resizable-handle-n {
  top: -5px;
  right: 0;
  bottom: auto;
  left: 0;
  height: 10px;
  cursor: n-resize;
}

.photoviewer-resizable-handle-se {
  top: auto;
  right: -5px;
  bottom: -5px;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: se-resize;
}

.photoviewer-resizable-handle-sw {
  top: auto;
  right: auto;
  bottom: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: sw-resize;
}

.photoviewer-resizable-handle-nw {
  top: -5px;
  right: auto;
  bottom: auto;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: nw-resize;
}

.photoviewer-resizable-handle-ne {
  top: -5px;
  right: -5px;
  bottom: auto;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: ne-resize;
}

:-webkit-full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

:-webkit-full-screen .photoviewer-header,
:-webkit-full-screen .photoviewer-footer,
:-webkit-full-screen .photoviewer-resizable-handle {
  display: none;
}

:-webkit-full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:-moz-full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

:-moz-full-screen .photoviewer-header,
:-moz-full-screen .photoviewer-footer,
:-moz-full-screen .photoviewer-resizable-handle {
  display: none;
}

:-moz-full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:-ms-fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

:-ms-fullscreen .photoviewer-header,
:-ms-fullscreen .photoviewer-footer,
:-ms-fullscreen .photoviewer-resizable-handle {
  display: none;
}

:-ms-fullscreen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

:full-screen .photoviewer-header,
:full-screen .photoviewer-footer,
:full-screen .photoviewer-resizable-handle {
  display: none;
}

:full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

:fullscreen .photoviewer-header,
:fullscreen .photoviewer-footer,
:fullscreen .photoviewer-resizable-handle {
  display: none;
}

:fullscreen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

::backdrop {
  background-color: black;
}

::-ms-backdrop {
  background-color: black;
}

.is-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.is-grabbing {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.photoviewer-loader {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 2;
  text-align: center;
  color: #333;
}

.photoviewer-loader::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
  animation: photoviewerLoading 1s infinite linear;
}

.photoviewer-loader::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

@keyframes photoviewerLoading {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
.mat-button-loading .mdc-button__label,
.mat-button-loading .mat-icon {
  visibility: hidden;
}

.mat-button-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable selector-no-vendor-prefix */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #7b1fa2;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #301d5e;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #7b1fa2;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #7b1fa2;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #301d5e;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #301d5e;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #301d5e;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #301d5e;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Open Sans;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Open Sans;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Open Sans;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Open Sans;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #7b1fa2;
  --mdc-linear-progress-track-color: rgba(123, 31, 162, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(123, 31, 162, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(123, 31, 162, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(123, 31, 162, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(123, 31, 162, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(123, 31, 162, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #301d5e;
  --mdc-linear-progress-track-color: rgba(48, 29, 94, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(48, 29, 94, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(48, 29, 94, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(48, 29, 94, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(48, 29, 94, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(48, 29, 94, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-caret-color: #7b1fa2;
  --mdc-filled-text-field-focus-active-indicator-color: #7b1fa2;
  --mdc-filled-text-field-focus-label-text-color: rgba(123, 31, 162, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #7b1fa2;
  --mdc-outlined-text-field-focus-outline-color: #7b1fa2;
  --mdc-outlined-text-field-focus-label-text-color: rgba(123, 31, 162, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(123, 31, 162, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(48, 29, 94, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #301d5e;
  --mdc-filled-text-field-focus-active-indicator-color: #301d5e;
  --mdc-filled-text-field-focus-label-text-color: rgba(48, 29, 94, 0.87);
  --mdc-outlined-text-field-caret-color: #301d5e;
  --mdc-outlined-text-field-focus-outline-color: #301d5e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(48, 29, 94, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Open Sans;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Open Sans;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Open Sans;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(123, 31, 162, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(48, 29, 94, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-trigger-text-font: Open Sans;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Open Sans;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Open Sans;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #7b1fa2;
  --mdc-chip-elevated-disabled-container-color: #7b1fa2;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #301d5e;
  --mdc-chip-elevated-disabled-container-color: #301d5e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #7b1fa2;
  --mdc-switch-selected-handle-color: #7b1fa2;
  --mdc-switch-selected-hover-state-layer-color: #7b1fa2;
  --mdc-switch-selected-pressed-state-layer-color: #7b1fa2;
  --mdc-switch-selected-focus-handle-color: #130a31;
  --mdc-switch-selected-hover-handle-color: #130a31;
  --mdc-switch-selected-pressed-handle-color: #130a31;
  --mdc-switch-selected-focus-track-color: #6e618e;
  --mdc-switch-selected-hover-track-color: #6e618e;
  --mdc-switch-selected-pressed-track-color: #6e618e;
  --mdc-switch-selected-track-color: #6e618e;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #7b1fa2;
  --mdc-switch-selected-handle-color: #7b1fa2;
  --mdc-switch-selected-hover-state-layer-color: #7b1fa2;
  --mdc-switch-selected-pressed-state-layer-color: #7b1fa2;
  --mdc-switch-selected-focus-handle-color: #130a31;
  --mdc-switch-selected-hover-handle-color: #130a31;
  --mdc-switch-selected-pressed-handle-color: #130a31;
  --mdc-switch-selected-focus-track-color: #6e618e;
  --mdc-switch-selected-hover-track-color: #6e618e;
  --mdc-switch-selected-pressed-track-color: #6e618e;
  --mdc-switch-selected-track-color: #6e618e;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Open Sans;
  --mat-slide-toggle-label-text-size: 14px;
  --mat-slide-toggle-label-text-tracking: 0.0178571429em;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b1fa2;
  --mdc-radio-selected-hover-icon-color: #7b1fa2;
  --mdc-radio-selected-icon-color: #7b1fa2;
  --mdc-radio-selected-pressed-icon-color: #7b1fa2;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #7b1fa2;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #301d5e;
  --mdc-radio-selected-hover-icon-color: #301d5e;
  --mdc-radio-selected-icon-color: #301d5e;
  --mdc-radio-selected-pressed-icon-color: #301d5e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #301d5e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #7b1fa2;
  --mdc-slider-focus-handle-color: #7b1fa2;
  --mdc-slider-hover-handle-color: #7b1fa2;
  --mdc-slider-active-track-color: #7b1fa2;
  --mdc-slider-inactive-track-color: #7b1fa2;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #7b1fa2;
  --mat-mdc-slider-ripple-color: #7b1fa2;
  --mat-mdc-slider-hover-ripple-color: rgba(123, 31, 162, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(123, 31, 162, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #301d5e;
  --mdc-slider-focus-handle-color: #301d5e;
  --mdc-slider-hover-handle-color: #301d5e;
  --mdc-slider-active-track-color: #301d5e;
  --mdc-slider-inactive-track-color: #301d5e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #301d5e;
  --mat-mdc-slider-ripple-color: #301d5e;
  --mat-mdc-slider-hover-ripple-color: rgba(48, 29, 94, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(48, 29, 94, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Open Sans;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Open Sans;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #7b1fa2;
  --mdc-radio-selected-hover-icon-color: #7b1fa2;
  --mdc-radio-selected-icon-color: #7b1fa2;
  --mdc-radio-selected-pressed-icon-color: #7b1fa2;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #301d5e;
  --mdc-radio-selected-hover-icon-color: #301d5e;
  --mdc-radio-selected-icon-color: #301d5e;
  --mdc-radio-selected-pressed-icon-color: #301d5e;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #7b1fa2;
  --mdc-checkbox-selected-hover-icon-color: #7b1fa2;
  --mdc-checkbox-selected-icon-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b1fa2;
  --mdc-checkbox-selected-hover-state-layer-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #301d5e;
  --mdc-checkbox-selected-hover-icon-color: #301d5e;
  --mdc-checkbox-selected-icon-color: #301d5e;
  --mdc-checkbox-selected-pressed-icon-color: #301d5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #301d5e;
  --mdc-checkbox-selected-hover-state-layer-color: #301d5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #301d5e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #7b1fa2;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #7b1fa2;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Open Sans;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Open Sans;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Open Sans;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: Open Sans;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #7b1fa2;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #7b1fa2;
  --mat-tab-header-active-ripple-color: #7b1fa2;
  --mat-tab-header-inactive-ripple-color: #7b1fa2;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #7b1fa2;
  --mat-tab-header-active-hover-label-text-color: #7b1fa2;
  --mat-tab-header-active-focus-indicator-color: #7b1fa2;
  --mat-tab-header-active-hover-indicator-color: #7b1fa2;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #301d5e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #301d5e;
  --mat-tab-header-active-ripple-color: #301d5e;
  --mat-tab-header-inactive-ripple-color: #301d5e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #301d5e;
  --mat-tab-header-active-hover-label-text-color: #301d5e;
  --mat-tab-header-active-focus-indicator-color: #301d5e;
  --mat-tab-header-active-hover-indicator-color: #301d5e;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #7b1fa2;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #301d5e;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #301d5e;
  --mdc-checkbox-selected-hover-icon-color: #301d5e;
  --mdc-checkbox-selected-icon-color: #301d5e;
  --mdc-checkbox-selected-pressed-icon-color: #301d5e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #301d5e;
  --mdc-checkbox-selected-hover-state-layer-color: #301d5e;
  --mdc-checkbox-selected-pressed-state-layer-color: #301d5e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #7b1fa2;
  --mdc-checkbox-selected-hover-icon-color: #7b1fa2;
  --mdc-checkbox-selected-icon-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #7b1fa2;
  --mdc-checkbox-selected-hover-state-layer-color: #7b1fa2;
  --mdc-checkbox-selected-pressed-state-layer-color: #7b1fa2;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #7b1fa2;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #301d5e;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #7b1fa2;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #301d5e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #7b1fa2;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #301d5e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #7b1fa2;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #301d5e;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #301d5e;
  --mat-mdc-button-ripple-color: rgba(48, 29, 94, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #7b1fa2;
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #301d5e;
  --mat-mdc-button-persistent-ripple-color: #301d5e;
  --mat-mdc-button-ripple-color: rgba(48, 29, 94, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #7b1fa2;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #301d5e;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Open Sans;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #301d5e;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Open Sans;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Open Sans;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Open Sans;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Open Sans;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #7b1fa2;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #301d5e;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #7b1fa2;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #301d5e;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Open Sans;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Open Sans;
  --mat-standard-button-toggle-text-font: Open Sans;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #7b1fa2;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(123, 31, 162, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(123, 31, 162, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(123, 31, 162, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #7b1fa2;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(123, 31, 162, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #301d5e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(48, 29, 94, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(48, 29, 94, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(48, 29, 94, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(48, 29, 94, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #301d5e;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Open Sans;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #7b1fa2;
}
.mat-icon.mat-accent {
  --mat-icon-color: #301d5e;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #7b1fa2;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #7b1fa2;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #7b1fa2;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #301d5e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #301d5e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #301d5e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Open Sans;
  --mat-stepper-header-label-text-font: Open Sans;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #7b1fa2;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #301d5e;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Open Sans;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Open Sans;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mtx-alert {
  background-color: white;
}

.mtx-alert-info.mtx-alert {
  background-color: #2196f3;
  color: white;
}

.mtx-alert-success.mtx-alert {
  background-color: #4caf50;
  color: white;
}

.mtx-alert-warning.mtx-alert {
  background-color: #ff9800;
  color: white;
}

.mtx-alert-danger.mtx-alert {
  background-color: #f44336;
  color: white;
}

.mtx-colorpicker-toggle-active {
  color: #7b1fa2;
}
.mtx-colorpicker-toggle-active.mat-accent {
  color: #301d5e;
}
.mtx-colorpicker-toggle-active.mat-warn {
  color: #f44336;
}

.mtx-datetimepicker-content {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mtx-calendar-header {
  background-color: #7b1fa2;
  color: white;
}

.mtx-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mtx-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mtx-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
}
.mtx-calendar-body-cell-content.mtx-calendar-body-selected {
  background-color: #7b1fa2;
  color: white;
}
.mtx-calendar-body-cell-content.mtx-calendar-body-selected.mtx-calendar-body-today {
  box-shadow: inset 0 0 0 1px white;
}
.mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected).mtx-calendar-body-today {
  border-color: rgba(0, 0, 0, 0.38);
}

.mtx-calendar-body-cell:not(.mtx-calendar-body-disabled):hover > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected),
.mtx-calendar-body-active > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected) {
  background-color: rgba(123, 31, 162, 0.3);
}

.mtx-calendar-body-disabled > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}
.mtx-calendar-body-disabled > .mtx-calendar-body-today:not(.mtx-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mtx-calendar-body-disabled > .mtx-calendar-body-selected {
  background-color: rgba(123, 31, 162, 0.4);
}

.mtx-calendar-previous-button.disabled,
.mtx-calendar-next-button.disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mtx-clock-wrapper {
  background-color: rgba(0, 0, 0, 0.12);
}

.mtx-clock-center {
  background-color: #7b1fa2;
}

.mtx-clock-hand {
  background-color: #7b1fa2;
}
.mtx-clock-hand::before {
  background-color: #7b1fa2;
}

.mtx-clock-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mtx-clock-cell.mtx-clock-cell-selected {
  background-color: #7b1fa2;
  color: white;
}
.mtx-clock-cell:not(.mtx-clock-cell-selected, .mtx-clock-cell-disabled):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.mtx-clock-cell.mtx-clock-cell-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mtx-datetimepicker-toggle-active {
  color: #7b1fa2;
}
.mtx-datetimepicker-toggle-active.mat-accent {
  color: #301d5e;
}
.mtx-datetimepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mtx-time-input {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.12);
}
.mtx-time-input.mtx-time-input-active {
  color: #7b1fa2;
  background-color: rgba(123, 31, 162, 0.2);
}
.mtx-time-input.mtx-time-input-active:focus {
  border-color: #7b1fa2;
  background-color: #fafafa;
}
.mtx-time-input.mtx-time-input-active:focus::placeholder {
  color: rgba(123, 31, 162, 0.6);
}
.mtx-time-input.mtx-time-input-warning {
  border-color: #f44336;
}

.mtx-time-ampm .mtx-time-am,
.mtx-time-ampm .mtx-time-pm {
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.75);
  border-color: rgba(0, 0, 0, 0.38);
}
.mtx-time-ampm .mtx-time-am.mtx-time-ampm-active,
.mtx-time-ampm .mtx-time-pm.mtx-time-ampm-active {
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-typography-button-font-weight: 500;
  background-color: rgba(48, 29, 94, 0.2);
}

.mtx-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mtx-calendar-body {
  font-size: 13px;
}

.mtx-calendar-body-label,
.mtx-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mtx-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mtx-clock {
  font-size: 14px;
}

.mtx-drawer-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mtx-grid {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.mtx-grid .mat-table-sticky-left,
.mtx-grid .mat-table-sticky-right {
  border-color: rgba(0, 0, 0, 0.12);
}
.mtx-grid .mat-mdc-table.mat-table-striped .mat-row-odd {
  background-color: #f5f5f5;
}
.mtx-grid .mat-mdc-table.mat-table-hover .mat-mdc-row:hover {
  background-color: #e5e5e5;
}
.mtx-grid .mat-mdc-table .mat-mdc-row.selected, .mtx-grid .mat-mdc-table .mat-mdc-row.mat-row-odd.selected {
  background-color: #e5e5e5;
}
.mtx-grid .mat-mdc-table .mat-mdc-cell.selected {
  box-shadow: inset 0 0 0 1px #301d5e;
}
.mtx-grid .mat-mdc-footer-row .mat-mdc-footer-cell {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-width: 0;
  background-color: whitesmoke;
}
.mtx-grid .mat-mdc-paginator {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.mtx-grid-toolbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mtx-grid-sidebar {
  border-color: rgba(0, 0, 0, 0.2);
}

.mtx-grid-statusbar {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.mtx-grid-column-menu-item {
  color: rgba(0, 0, 0, 0.87);
}

.mtx-grid-column-menu-header,
.mtx-grid-column-menu-footer {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

.mtx-grid-column-menu-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mtx-grid-column-menu-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex .mat-mdc-header-cell,
.mat-column-resize-flex .mat-mdc-cell {
  box-sizing: border-box;
  min-width: 32px;
}

.mat-mdc-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-mdc-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: transparent;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}

.mat-mdc-header-cell:not(.mat-resizable)::after {
  content: "";
}

[dir=rtl] .mat-mdc-header-cell:not(.mat-resizable)::after,
[dir=rtl] .mat-resizable-handle {
  left: 0;
  right: auto;
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell:not(.mat-resizable)::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle {
  background: #988eaf;
}

.mat-resizable.cdk-resizable-overlay-thumb-active > .mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  background: #301d5e;
  outline: none;
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  height: 100%;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}
.mat-column-resize-overlay-thumb:active {
  background: linear-gradient(90deg, transparent, transparent 7px, #301d5e, #301d5e 1px, transparent 8px, transparent);
  will-change: transform;
}

.mtx-loader-backdrop {
  background-color: rgba(250, 250, 250, 0.75);
}

.mtx-popover-panel {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

[class*=mtx-popover] .mtx-popover-direction-arrow::before {
  border-color: rgba(0, 0, 0, 0.12);
}
[class*=mtx-popover] .mtx-popover-direction-arrow::after {
  border-color: white;
}

[class*=mtx-popover-below] .mtx-popover-direction-arrow::before, [class*=mtx-popover-below] .mtx-popover-direction-arrow::after,
[class*=mtx-popover-above] .mtx-popover-direction-arrow::before,
[class*=mtx-popover-above] .mtx-popover-direction-arrow::after {
  border-left-color: transparent;
  border-right-color: transparent;
}

[class*=mtx-popover-before] .mtx-popover-direction-arrow::before, [class*=mtx-popover-before] .mtx-popover-direction-arrow::after,
[class*=mtx-popover-after] .mtx-popover-direction-arrow::before,
[class*=mtx-popover-after] .mtx-popover-direction-arrow::after {
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.mtx-progress {
  background-color: rgba(0, 0, 0, 0.04);
}

.mtx-progress-fill-info {
  background-color: #2196f3;
  color: white;
}

.mtx-progress-fill-success {
  background-color: #4caf50;
  color: white;
}

.mtx-progress-fill-warning {
  background-color: #ff9800;
  color: white;
}

.mtx-progress-fill-danger {
  background-color: #f44336;
  color: white;
}

.ng-select .ng-select-container {
  color: rgba(0, 0, 0, 0.87);
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: rgba(0, 0, 0, 0.87);
}
.ng-select.ng-select-disabled .ng-value {
  color: rgba(0, 0, 0, 0.38);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  opacity: 0.4;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.ng-select .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.54);
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #f44336;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: rgba(0, 0, 0, 0.54) transparent transparent;
}
.ng-select.ng-select-invalid .ng-arrow-wrapper .ng-arrow {
  border-color: #f44336 transparent transparent;
}
.ng-select.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent rgba(0, 0, 0, 0.54);
}
.ng-select.ng-select-opened.ng-select-invalid .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent #f44336;
}

.ng-dropdown-panel {
  background-color: white;
}
.ng-dropdown-panel.multiple .ng-option.selected {
  background: rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.multiple .ng-option.marked {
  background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: rgba(0, 0, 0, 0.54);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: rgba(0, 0, 0, 0.87);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--outlined .ng-select {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  margin-bottom: -16px;
}

.ng-select {
  padding-top: 24px;
  padding-bottom: 8px;
  margin-top: -24px;
  margin-bottom: -8px;
}

.mdc-text-field--no-label .ng-select {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  margin-bottom: -16px;
}

.mtx-slider-thumb {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mtx-slider-track-background {
  background-color: rgb(189, 189, 189);
}

.mat-primary .mtx-slider-track-fill,
.mat-primary .mtx-slider-thumb,
.mat-primary .mtx-slider-thumb-label {
  background-color: #7b1fa2;
}
.mat-primary .mtx-slider-thumb-label-text {
  color: white;
}
.mat-primary .mtx-slider-focus-ring {
  background-color: rgba(123, 31, 162, 0.2);
}

.mat-accent .mtx-slider-track-fill,
.mat-accent .mtx-slider-thumb,
.mat-accent .mtx-slider-thumb-label {
  background-color: #301d5e;
}
.mat-accent .mtx-slider-thumb-label-text {
  color: white;
}
.mat-accent .mtx-slider-focus-ring {
  background-color: rgba(48, 29, 94, 0.2);
}

.mat-warn .mtx-slider-track-fill,
.mat-warn .mtx-slider-thumb,
.mat-warn .mtx-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mtx-slider-thumb-label-text {
  color: white;
}
.mat-warn .mtx-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mtx-slider:hover .mtx-slider-track-background,
.mtx-slider.cdk-focused .mtx-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mtx-slider-disabled .mtx-slider-track-background {
  background-color: rgba(189, 189, 189, 0.54);
}
.mtx-slider-disabled .mtx-slider-track-fill,
.mtx-slider-disabled .mtx-slider-thumb {
  background-color: rgb(189, 189, 189);
}
.mtx-slider-disabled:hover .mtx-slider-track-background, .mtx-slider-disabled.cdk-focused .mtx-slider-track-background {
  background-color: rgba(189, 189, 189, 0.54);
}

.mtx-slider-min-value .mtx-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mtx-slider-min-value.mtx-slider-thumb-label-showing:not(.mtx-range-slider) .mtx-slider-thumb,
.mtx-slider-min-value.mtx-slider-thumb-label-showing:not(.mtx-range-slider) .mtx-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mtx-slider-min-value.mtx-slider-thumb-label-showing:not(.mtx-range-slider).cdk-focused .mtx-slider-thumb,
.mtx-slider-min-value.mtx-slider-thumb-label-showing:not(.mtx-range-slider).cdk-focused .mtx-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mtx-slider-min-value:not(.mtx-slider-thumb-label-showing, .mtx-range-slider) .mtx-slider-thumb {
  background-color: rgb(189, 189, 189);
}
.mtx-slider-min-value:not(.mtx-slider-thumb-label-showing, .mtx-range-slider):hover .mtx-slider-thumb, .mtx-slider-min-value:not(.mtx-slider-thumb-label-showing, .mtx-range-slider).cdk-focused .mtx-slider-thumb {
  background-color: rgba(0, 0, 0, 0.38);
}
.mtx-slider-min-value:not(.mtx-slider-thumb-label-showing, .mtx-range-slider):hover.mtx-slider-disabled .mtx-slider-thumb, .mtx-slider-min-value:not(.mtx-slider-thumb-label-showing, .mtx-range-slider).cdk-focused.mtx-slider-disabled .mtx-slider-thumb {
  border-color: rgb(189, 189, 189);
}

.mtx-slider-has-ticks .mtx-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mtx-slider-horizontal .mtx-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mtx-slider-vertical .mtx-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mtx-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.mtx-split > .mtx-split-gutter {
  background-color: rgba(0, 0, 0, 0.12);
}
.mtx-split > .mtx-split-gutter:hover {
  background-color: #7b1fa2;
}
.mtx-split > .mtx-split-gutter.mat-accent:hover {
  background-color: #301d5e;
}
.mtx-split > .mtx-split-gutter.mat-warn:hover {
  background-color: #f44336;
}

.mtx-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mtx-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Open Sans;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Open Sans;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Open Sans;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Open Sans;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  font-family: Open Sans;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 96px;
  font-family: Open Sans;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Open Sans;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.loader, .loader::before, .loader::after {
  width: 1em;
  height: 4em;
  background: #9d45db;
  animation: load1 1s infinite ease-in-out;
}
.loader {
  margin: 222px auto;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  color: #9d45db;
  text-indent: -9999em;
  animation-delay: -0.16s;
}
.loader::before, .loader::after {
  content: "";
  position: absolute;
  top: 0;
}
.loader::before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.loader::after {
  left: 1.5em;
}

@keyframes load1 {
  0%, 80%, 100% {
    height: 4em;
    box-shadow: 0 0;
  }
  40% {
    height: 5em;
    box-shadow: 0 -2em;
  }
}